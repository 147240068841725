<template>
  <el-dialog
      v-bind="$attrs"
      width="75%"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      :lock-scroll="true"
      v-on="$listeners"
      @open="onOpen"
      @close="onClose"
  >
    <div class="dialogView">
      <div class="leftView">
        <el-scrollbar style="height: 50vh">
          <el-tree :data="treeData" :props="defaultProps" default-expand-all @node-click="handleNodeClick">
            <template slot-scope="{ node, data }">
              <span :style="data.id == categoryId?'color:#006eff;':''">{{ data.name }}</span>
            </template>
          </el-tree>
        </el-scrollbar>
      </div>
      <div class="rightView">
        <div class="fucView">
          <el-input placeholder="请输入内容" v-model="keyWord" class="search">
            <el-button slot="append" icon="el-icon-search" @click="searchHandle"></el-button>
          </el-input>
          <el-radio-group v-model="goodStatus" @change="goodsStatusChangeHandle">
            <el-radio-button v-for="(item,index) in statusList" :key="index" :label="item.value">{{ item.label }}
            </el-radio-button>
          </el-radio-group>
        </div>
        <div class="pageView tableView">
          <el-table size="medium" border fit :data="tableList" ref="multipleTable" show-header
                    :height="windowHeight" @selection-change="handleSelectionChange" @row-click="rowClickHandle">
            <el-table-column
                type="selection"
                width="55">
            </el-table-column>
            <el-table-column label="商品">
              <template slot-scope="scope">
                <div class="goodsInfoView">
                  <el-image :src="scope.row.imgUrl || scope.row.gallery" class="goodsPic"></el-image>
                  <span class="goodsName">{{ scope.row.name }}</span>
                </div>
              </template>
            </el-table-column>
            <template v-if="!chooseGoods">
              <el-table-column prop="stock" label="库存"/>
              <el-table-column prop="price" label="价格"/>
            </template>

          </el-table>
        </div>
        <div class="pageView paginationView">
          <el-pagination layout="prev, pager, next" :current-page.sync="page" :total="pageCount"
                         @current-change="pageChangeHandle"/>
        </div>
      </div>
    </div>
    <div slot="footer">
      <el-button @click="close">
        取消
      </el-button>
      <el-button type="primary" @click="handelConfirm">
        确定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import {apiGetCategoryList, apiGetGoodsChooseSKU, apiGetGoodsChooseGoods} from "@/request/api"
import chooseGoods from "@/views/tools/components/chooseGoods";

export default {
  inheritAttrs: false,
  props: {
    chooseGoods: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      windowHeight: (window.screen.height / 2) - 172,
      keyWord: '',
      pageCount: 0,
      statusList: [
        {
          label: '所有',
          value: 0
        },
        {
          label: '不显示无库存',
          value: 1
        }
      ],
      goodStatus: 0,
      categoryId: '',
      defaultProps: {
        children: 'subclass',
        label: 'name'
      },
      tableList: [],
      treeData: [],
      page: 1,
      multipleSelection: []
    }
  },
  methods: {
    onOpen() {
      this.getCategoryList();
      this.getGoodsList();
    },
    onClose() {
      this.keyWord = ''
      this.goodStatus = 0
      this.categoryId = ''
      this.page = 1
    },
    close(e) {
      this.$emit('update:visible', false)
    },
    handelConfirm() {
      this.$emit('confirm', this.multipleSelection)
      this.close()
    },
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val
      var newList = [];
      if (this.multipleSelection.length == 0) {
        this.multipleSelection = val;
        return
      }
      val.forEach(valItem => {
        var bool = this.multipleSelection.some(multipleItem => {
          if (chooseGoods) {
            return multipleItem.goods_id == valItem.goods_id
          } else {
            return multipleItem.skuid != valItem.skuid && multipleItem.goods_id != valItem.goods_id
          }
        })
        if (!bool) {
          newList.push(valItem)
        }
      })
      this.multipleSelection = [...this.multipleSelection, ...newList]
    },
    rowClickHandle(row) {
      // this.multipleSelection.push(row)
      this.$refs.multipleTable.toggleRowSelection(row)
    },

    handleNodeClick(data) {
      this.categoryId = data.id
      this.page = 1
      this.keyWord = ''
      this.goodStatus = 0
      this.getGoodsList()
    },
    pageChangeHandle() {
      this.getGoodsList()
    },
    goodsStatusChangeHandle() {
      this.page = 1
      this.getGoodsList()
    },
    searchHandle() {
      this.page = 1
      this.goodStatus = 0
      this.getGoodsList()
    },
    getGoodsList() {
      if (this.chooseGoods) {
        apiGetGoodsChooseGoods({
          category_id: this.categoryId,
          page: this.page,
          keyword: this.keyWord,
          has_stock: this.goodStatus,
          'per-page': 10,
        }).then(res => {
          this.tableList = res.data.list
          this.pageCount = res.data.count
        })
      } else {
        apiGetGoodsChooseSKU({
          category_id: this.categoryId,
          page: this.page,
          keyword: this.keyWord,
          has_stock: this.goodStatus,
          'per-page': 10,
        }).then(res => {
          this.tableList = res.data.list
          this.pageCount = res.data.count
        })
      }

    },
    getCategoryList() {
      apiGetCategoryList().then(res => {
        if (res.code === 200) {
          this.treeData = res.data;
        }
      });
    },
  },
}
</script>

<style lang="less" scoped>
.dialogView {
  height: 50vh;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #dcdfe6;

  .leftView {
    width: 250px;
    height: 50vh;
    border-right: 1px solid #dcdfe6;
    padding: 0 5px;
    box-sizing: border-box;
  }

  .rightView {
    width: calc(100% - 250px);
    height: 50vh;
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    box-sizing: border-box;

    .pageView {
      margin-top: 15px;
    }

    .tableView {
      height: calc(100% - 40px - 30px - 32px);
    }

    .paginationView {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }

    .fucView {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .search {
        width: 25%;
      }
    }
  }
}

.goodsInfoView {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  align-items: center;
}

.goodsInfoView .goodsPic {
  width: 45px;
  height: 45px;
  border-radius: 10px;
  margin-right: 10px
}

.goodsInfoView .goodsName {
  max-height: 50px;
  width: calc(100% - 45px - 10px);
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
