<template>
  <el-form label-width="120px" label-position="left">
    <el-row :gutter="20">
      <el-col :span="18">
        <el-form-item label="搜索商品">
          <el-radio-group v-model="searchType">
            <el-radio-button label="1">根据分类</el-radio-button>
            <el-radio-button label="2" v-if="!chooseGoods">根据货号(SKU)</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <template v-if="searchType == 1">
          <el-button @click="showPop = true" style="margin-top: 10px;margin-left: 120px">选择商品</el-button>
          <PopView :visible.sync="showPop" :chooseGoods="chooseGoods" title="选择商品" @confirm="showPopSubmit"></PopView>
        </template>
        <template v-if="searchType == 2">
          <el-form-item label="">
            <div style="display: flex;align-items: flex-end">
              <el-input type="textarea" :rows="5" v-model="skuList" placeholder="一行一个SKU"/>
              <el-button style="margin-left: 10px" @click="searchSku">搜索</el-button>
            </div>
          </el-form-item>
        </template>
        <el-table :data="goodsList" border style="margin-top: 10px;margin-left: 120px">
          <el-table-column v-if="chooseGoods" label="商品ID" prop="goods_id" width="130" />
          <el-table-column v-else label="商品编码(SKU)" prop="skuid" width="130"/>
          <el-table-column label="商品信息">
            <template slot-scope="scope">
              <div class="goodsInfo" >
                <el-image :src="scope.row.imgUrl || scope.row.gallery"/>
                <span>{{scope.row.name}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="商品销售价" prop="price" v-if="!chooseGoods">
          </el-table-column>
          <el-table-column label="商品库存" prop="stock" v-if="!chooseGoods">
          </el-table-column>
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <el-button type="text" @click="goodsList.splice(scope.$index,1)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import PopView from "@/views/components/popView";
import {apiGetGoodsChooseBySKU} from "@/request/api";
export default {
  name: "chooseGoods",
  components: {
    PopView
  },
  watch: {
    goodsList: {
      handler(val) {
        this.$emit("handleChange", this.goodsList);
      },
      deep: true
    }
  },
  props:{
    chooseGoods: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchType: 1,
      skuList: "",
      showPop: false,
      goodsList:[]
    };
  },
  methods:{
    showPopSubmit(e){
      this.showPop = false;
      this.duplicate(e)
    },
    duplicate(dataList){
      var newList = []
      dataList.forEach(dataItem => {
        var bool = this.goodsList.some(goodsItem => {
          return goodsItem.skuid == dataItem.skuid;
        });
        if(!bool) {
          newList.push(dataItem);
        }
      });
      this.goodsList = [...this.goodsList,...newList];
    },
    searchSku(){
      apiGetGoodsChooseBySKU({sku:this.skuList}).then(res => {
        if(res.code == 200){
          this.duplicate(res.data.list);
        }
      })
    }
  }
}
</script>

<style lang="less" >
.el-form-item {
  margin-bottom: 10px;
}
.searchView {
  display: flex;
  flex-direction: row;
  align-items: center;

  .el-input {
    margin-right: 10px;
  }

  .el-select {
    margin-right: 10px;
  }
}

.goodsInfo {
  display: flex;
  .el-image {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
}
.text-danger {
  display: inline-block;
  color: #f00;
  font-size: .24rem;
  margin-top: 5px;
}
.text-danger::before {
  content: "*";
  color: #f00;
  font-size: .24rem;
  margin-right: 5px;
}
</style>