<template>
  <div class="page">
    <el-row>
      <el-col :span="14">
        <h2>商品</h2>
        <el-form ref="form" label-width="110px" :model="form" :rules="rules">
          <el-table :data="s_list" border>
            <el-table-column label="商品信息">
              <template slot-scope="scope">
                <div class="goodsInfo">
                  <el-image :src="scope.row.imgUrl"
                            style="width: 50px;height: 50px;border-radius: 10px;margin-right: 10px"></el-image>
                  <span>{{ scope.row.name }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="商品原价">
              <template slot-scope="scope">
                <span>￥{{ scope.row.price }}</span>
              </template>
            </el-table-column>
            <el-table-column label="库存" prop="stock"></el-table-column>
            <el-table-column label="阶梯价格" width="320px">
              <template slot-scope="scope">
                <div class="priceTable">
                  <div class="tableHeader">
                    <div>数量</div>
                    <div>价格</div>
                    <div>操作</div>
                  </div>
                  <div class="tableBody" v-for="(priceItem,priceIndex) in scope.row.ladder_price">
                    <div>
                      <el-input size="mini" v-model="priceItem.number"/>
                    </div>
                    <div>
                      <el-input size="mini" v-model="priceItem.price"/>
                    </div>
                    <div>
                      <el-button type="text" @click="delPrice(scope.$index,priceIndex)">删除</el-button>
                    </div>
                  </div>
                  <div class="add" >
                    <el-button type="text" icon="el-icon-plus" @click="addPrice(scope.$index,scope.row)"/>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="120px">
              <template slot-scope="scope">
                <el-button type="text" :disabled="del_state" size="small" @click="goodsList.splice(scope.$index,1)">删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-button @click="showPop = true" :disabled="del_state" style="margin-top: 10px">选择商品</el-button>
          <div class="fucView">
            <el-button type="primary" @click="submit">保存</el-button>
            <el-button @click="close">返回</el-button>
          </div>
        </el-form>
      </el-col>
    </el-row>
    <PopView :visible.sync="showPop" title="选择商品" @confirm="showPopSubmit"></PopView>
  </div>

</template>

<script>
import PopView from "@/views/components/popView";
import {apiOperationWholesale} from "@/request/api";

export default {
  name: "wholesales",
  components: {PopView},
  props: {
    chooseGoods: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      del_state: false,
      showPop: false,
      form: {},
      rules: {},
      list: [],
      s_list: []
    }
  },
  created() {
    if (this.$route.query.row) {
      this.getGoods()
    }
  },
  methods: {
    getGoods() {
      const tempObj = this.$route.query.row
      this.s_list = [{
        id: tempObj.id,
        sku_id: tempObj.sku_id,
        imgUrl: tempObj.sku_info.imgUrl,//商品图片
        name: tempObj.sku_info.name,//商品名
        price: tempObj.sku_info.price,//价格
        stock: tempObj.sku_info.stock,//库存
        ladder_price: tempObj.ladder_price,
      }]
      this.del_state = true
    },
    //选择商品
    showPopSubmit(e) {
      this.showPop = true;
      console.log(e)
      if (e) {
        e.forEach(item => {
          this.s_list.push({
            id: '',
            sku_id: item.skuid,
            imgUrl: item.imgUrl,//商品图片
            name: item.name,//商品名
            price: item.price,//价格
            stock: item.stock,//库存
            ladder_price: [{
              number: 1,
              price: item.price,
            }],
          })
        })
        const map = new Map()
        // 对id属性进行去重
        this.s_list = this.s_list.filter(key => !map.has(key.sku_id) && map.set(key.sku_id, 1))
        this.listLoading = false
      }
    },
    //添加阶梯价格
    addPrice(index, row) {
      console.log(index)
      this.s_list[index].ladder_price.push({
        number: 1,
        price: row.price,
      })
    },
    //删除阶梯价格
    delPrice(index, cindex) {
      console.log(index, cindex)
      if (this.s_list[index].ladder_price.length == 1) {
        this.$message.error('阶梯价格不能为空')
        return
      }
      this.s_list[index].ladder_price.splice(cindex, 1)
    },
    close() {
      this.$refs['form'].resetFields()
      this.form = this.$options.data().form
      this.s_list = []
    },
    submit() {
      this.list = []
      if (this.s_list.length > 0) {
        this.s_list.forEach(item => {
          this.list.push({
            id: item.id,
            sku_id: item.sku_id,
            ladder_price: item.ladder_price,
          })
        })
      }
      apiOperationWholesale({data: this.list}).then(res => {
        if (res.code == 200) {
          this.$message.success(res.message)
          this.close()
        } else {
          this.$message.error(res.message)
        }
      })
    },
  }
}
</script>

<style scoped>
.page {
  background-color: #FFF;
  padding: 20px;
}

.goodsInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.priceTable {
  display: flex;
  flex-direction: row;
  border: 1px solid #ebeef5;
  max-width: 304px;
}

.priceTable .tableHeader {
  display: flex;
  flex-direction: column;
  width: 60px;
}

.priceTable .tableHeader div {
  text-align: center;
  height: 35px;
  line-height: 35px;
}

.priceTable .tableHeader div ~ div {
  border-top: 1px solid #ebeef5;
}

.priceTable .tableBody {
  display: flex;
  flex-direction: column;
  width: 80px;
  border-left: 1px solid #ebeef5;
}

.priceTable .tableBody div {
  text-align: center;
  height: 35px;
  line-height: 35px;
  padding: 0 5px;
}

.priceTable .tableBody div ~ div {
  border-top: 1px solid #ebeef5;
}

.priceTable .add {
  width: 80px;
  height: 105px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #ebeef5;
}

.fucView {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}
</style>
